<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" scrollable max-width="500px">
            <v-card class="lfont">
                <v-card-title class="lfont">{{ $t("change_password.modal.title") }}</v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                    <h2 class="text-h5 lfont"><span class="lfont">{{ $t("change_password.table.employee_name") }}:<span
                                class="text-primary"> {{
                                    employee.name }}
                                {{ employee.surname }}</span></span></h2><br>
                    <v-text-field prepend-inner-icon="mdi-key" v-model="new_password" label="Password"
                        @click:append="showPassword = !showPassword" outlined dense ref="pass">
                    </v-text-field>
                    <v-text-field prepend-inner-icon="mdi-key" v-model="confirm_password" label="Confirm Password"
                        @click:append="showPassword = !showPassword" outlined dense ref="pass">
                    </v-text-field>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-btn color="blue darken-1" text @click="save_change_password">
                        Save
                    </v-btn>
                    <v-btn color="red darken-1" text @click="$emit('close')">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>
<script>
export default {
    props: {
        dialog: "",
        employee: ""
    },
    data() {
        return {
            dialogm1: '',
            showPassword: false,
            new_password: "",
            confirm_password: "",
            server_errors: {
                user_id: "",
                new_password: "",
                confirm_password: ""
            }
        }
    },
    methods: {
        save_change_password() {
            let item = {
                user_id: this.employee.user_id,
                new_password: this.new_password,
                confirm_password: this.confirm_password,
            };
            this.$axios.post(`company/admin/change/password`, item).then((res) => {
                if (res.status === 200) {
                    this.$emit('close');
                    this.$notification.ShowErrors("top-right", "success", 3000, "ສຳເລັດ");
                }
                this.btnLoading = false;
            }).catch((error) => {
                this.btnLoading = false;
                if (error.response.status === 422) {
                    let obj = error.response.data.errors;
                    if (!obj) {
                        obj = error.response.data;
                        let res = "";
                        for (let [key, value] of Object.entries(obj)) {
                            res = value;
                        }
                        this.$notification.ShowErrors("top-right", "danger", 3000, res);
                    } else {
                        for (let [key, value] of Object.entries(obj)) {
                            this.server_errors[key] = value[0];
                            this.$notification.ShowErrors(
                                "top-right",
                                "danger",
                                3000,
                                (this.server_errors[key] = value[0])
                            );
                        }
                    }
                }
            })
        }
    },
}
</script>