<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t("change_password.title") }}</h1>
      </div>
    </div>
    <hr />
    <br />
    <div class="section-action">
      <v-row>
        <v-col cols="12" md="6" lg="6">
          <label class="label-input">{{
            $t("change_password.search_title")
          }}</label>
          <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense prepend-inner-icon="mdi-magnify"
            :placeholder="$t('Search.search')" v-model="filter" @keypress.enter="fetchEmployee">
          </v-text-field>
        </v-col>
      </v-row>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view" v-if="employees_list.length > 0">
          <thead>
            <tr>
              <th class="text-left">No</th>
              <th class="text-left">
                {{ $t("change_password.table.emp_number") }}
              </th>
              <th class="text-left">
                {{ $t("change_password.table.employee_name") }}
              </th>
              <th class="text-left">
                {{ $t("change_password.table.department") }}
              </th>
              <th class="text-left">
                {{ $t("change_password.table.branch") }}
              </th>
              <th class="text-left">
                {{ $t("change_password.table.email") }}
              </th>
              <th class="text-left">
                {{ $t("change_password.table.change_pass") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in employees_list" :key="idx">
              <td>{{ idx + 1 }}</td>
              <td>{{ item.emp_number }}</td>
              <td>{{ item.name }} {{ item.surname }}</td>
              <td>{{ item.department_name }}</td>
              <td>{{ item.branch_name }}</td>
              <td>{{ item.joinUser.email }}</td>
              <td>********
                <v-btn icon color="pink" @click="set_change_password(item.id)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </table>
        <defaultTableNoResult v-else />

        <Pagination class="mt-5 mb-5" v-if="pagination.total_pages > 1" :pagination="pagination" :offset="offset"
          @paginate="fetchEmployee">
        </Pagination>
        <ModalChangePassword :dialog="dialogChangePass" :employee="employee_change" @close="dialogChangePass = false" />
        <Loading v-if="isLoading" />
      </div>
    </div>
  </div>
</template>

<script>
import Pagination from "@/components/Paginate/Pagination";
import Loading from "@/components/Loading";
import defaultTableNoResult from "@/components/defaultTableNoResult";
import ModalChangePassword from "@/components/ChangePassword/ModalChangePassword.vue";

export default {
  components: {
    Pagination,
    Loading,
    defaultTableNoResult,
    ModalChangePassword
  },
  data() {
    return {
      dialogChangePass: false,
      isLoading: true,
      employees_list: [],
      filter: "",
      employee_change: "",

      pagination: {},
      offset: 10,
      per_page: 10,
      isLoading: true,
    };
  },
  methods: {
    set_change_password(id) {
      this.dialogChangePass = true;
      this.employee_change = this.employees_list.find(item => item.id === id);
    },
    fetchEmployee() {
      this.isLoading = true;
      this.$axios
        .get(`company/list-employee`, {
          params: {
            page: this.pagination.current_page,
            per_page: this.per_page,
            filter: this.filter,
          },
        })
        .then((res) => {
          if (res.status === 200) {
            this.isLoading = false;
            this.employees_list = res.data.data.data;
            this.pagination = res.data.data.pagination;
            if (!this.employees_list.length > 0) {
              this.pagination.current_page = this.pagination.current_page - 1;
            }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchEmployee();
  },
};
</script>

<style lang="scss" scoped>
.content {
  padding: 20px;
}
</style>
